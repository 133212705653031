// import React from 'react';
// import Layout from './src/components/Layout';
import "@fontsource/catamaran" // Defaults to weight 400 with all styles included.
import "@fontsource/catamaran/700.css" // Weight 500 with all styles included.
import "./src/styles/global.scss"

require('./src/assets/scss/index.scss');




// export function wrapPageElement({ element, props }) {
//     return <Layout {...props}>{element}</Layout>;
//   }
